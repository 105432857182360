var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.conteudoReady)?_c('Loading',{attrs:{"message":'documentação de apoio'}}):_c('v-card',{staticClass:"ma-4 pa-2",attrs:{"flat":""}},[_c('TogglePanelsCLAV',{attrs:{"n":_vm.panelsArrItems},on:{"alternar":function($event){_vm.panelsArr = $event}}}),_c('v-expansion-panels',{attrs:{"multiple":""},model:{value:(_vm.panelsArr),callback:function ($$v) {_vm.panelsArr=$$v},expression:"panelsArr"}},[_vm._l((this.docapoio),function(documentacao){return _c('PainelCLAV',{key:documentacao.classe,attrs:{"titulo":documentacao.classe,"infoHeader":documentacao.classe,"icon":_vm.docsicon},scopedSlots:_vm._u([{key:"conteudo",fn:function(){return [_c('v-expansion-panel-content',[_vm._l((documentacao.entradas),function(entrada){return _c('v-card-text',{key:entrada._id},[_c('p',{staticClass:"text-justify"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.compiledMarkdownOmmitParagraph(entrada.descricao))}}),_vm._l((_vm.operacoes_entradas),function(operacao,index){return _c('v-tooltip',{key:index,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":operacao.cor},on:{"click":function($event){return _vm.switchOperacaoEntrada(
                        operacao.descricao,
                        documentacao._id,
                        entrada._id
                      )}}},on),[_vm._v(_vm._s(operacao.icon))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(operacao.tooltip))])])})],2),_c('ul',_vm._l((entrada.elementos),function(elemento){return _c('div',{key:elemento._id},[(elemento.texto.rota)?_c('li',[_c('p',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.compiledMarkdownOmmitParagraph(elemento.texto.pre))}}),_c('span',{staticClass:"fakea",domProps:{"innerHTML":_vm._s(_vm.compiledMarkdownOmmitParagraph(elemento.texto.ligacao))},on:{"click":function($event){return _vm.downloadFileRota(elemento.texto.rota)}}}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.compiledMarkdownOmmitParagraph(elemento.texto.pos))}}),_vm._l((_vm.operacoes_elementos),function(operacao,index){return _c('v-tooltip',{key:index,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":operacao.cor},on:{"click":function($event){return _vm.switchOperacaoElemento(
                              operacao.descricao,
                              documentacao._id,
                              entrada._id,
                              elemento._id
                            )}}},on),[_vm._v(_vm._s(operacao.icon))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(operacao.tooltip))])])})],2)]):(elemento.texto.ligacao)?_c('li',[_c('p',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.compiledMarkdownOmmitParagraph(elemento.texto.pre))}}),_c('span',{staticClass:"fakea",domProps:{"innerHTML":_vm._s(_vm.compiledMarkdownOmmitParagraph(elemento.texto.ligacao))},on:{"click":function($event){return _vm.downloadFile(documentacao._id, entrada._id, elemento._id)}}}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.compiledMarkdownOmmitParagraph(elemento.texto.pos))}}),_vm._l((_vm.operacoes_elementos),function(operacao,index){return _c('v-tooltip',{key:index,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":operacao.cor},on:{"click":function($event){return _vm.switchOperacaoElemento(
                              operacao.descricao,
                              documentacao._id,
                              entrada._id,
                              elemento._id
                            )}}},on),[_vm._v(_vm._s(operacao.icon))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(operacao.tooltip))])])})],2)]):_c('li',{staticClass:"text-justify"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.compiledMarkdownOmmitParagraph(elemento.texto))}}),_vm._l((_vm.operacoes_elementos),function(operacao,index){return _c('v-tooltip',{key:index,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":operacao.cor},on:{"click":function($event){return _vm.switchOperacaoElemento(
                            operacao.descricao,
                            documentacao._id,
                            entrada._id,
                            elemento._id
                          )}}},on),[_vm._v(_vm._s(operacao.icon))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(operacao.tooltip))])])})],2)])}),0)])}),(_vm.level >= _vm.min)?_c('div',[_c('v-btn',{staticClass:"white--text ma-1",class:{
                'px-8': _vm.$vuetify.breakpoint.lgAndUp,
                'px-2': _vm.$vuetify.breakpoint.mdAndDown,
              },attrs:{"rounded":"","color":"success darken-1","id":"botao-shadow"},on:{"click":function($event){return _vm.go(("/documentacaoApoio/criar/entrada/" + (documentacao._id)))}}},[_c('unicon',{attrs:{"name":"criar-icon","width":"20","height":"20","viewBox":"0 0 20.714 20.71","fill":"#ffffff"}}),_c('span',{staticClass:"ml-2"},[_vm._v("Adicionar Entrada")])],1),_c('v-btn',{staticClass:"white--text ma-1",class:{
                'px-8': _vm.$vuetify.breakpoint.lgAndUp,
                'px-2': _vm.$vuetify.breakpoint.mdAndDown,
              },attrs:{"rounded":"","color":"neutral darken-2","id":"botao-shadow"},on:{"click":function($event){return _vm.go(("/documentacaoApoio/editar/classe/" + (documentacao._id)))}}},[_c('unicon',{attrs:{"name":"alterar-icon","width":"20","height":"20","viewBox":"0 0 20.714 20.71","fill":"#ffffff"}}),_c('span',{staticClass:"ml-2"},[_vm._v("Editar Secção")])],1),_c('v-btn',{staticClass:"white--text ma-1",class:{
                'px-8': _vm.$vuetify.breakpoint.lgAndUp,
                'px-2': _vm.$vuetify.breakpoint.mdAndDown,
              },attrs:{"rounded":"","color":"error","id":"botao-shadow"},on:{"click":function($event){return _vm.eliminaClasse(documentacao._id)}}},[_c('unicon',{attrs:{"name":"remove-icon","width":"20","height":"20","viewBox":"0 0 20.714 20.71","fill":"#ffffff"}}),_c('span',{staticClass:"ml-2"},[_vm._v("Eliminar Secção")])],1)],1):_vm._e()],2)]},proxy:true}],null,true)})}),_c('DocApoioProdTecCientifica',{attrs:{"level":_vm.level}})],2),_c('v-dialog',{attrs:{"value":_vm.eliminarIdClasse != '',"persistent":"","max-width":"290px"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Confirmar ação")]),_c('v-card-text',[_vm._v(" Tem a certeza que pretende eliminar a secção? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":function($event){_vm.eliminarIdClasse = ''}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.remover('Classe')}}},[_vm._v(" Confirmar ")])],1)],1)],1),_c('v-dialog',{attrs:{"value":_vm.eliminarIdEntrada != '',"persistent":"","max-width":"290px"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Confirmar ação")]),_c('v-card-text',[_vm._v(" Tem a certeza que pretende eliminar a entrada? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":function($event){_vm.eliminarIdEntrada = '';
            _vm.eliminarIdClasse = '';}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.remover('Entrada')}}},[_vm._v(" Confirmar ")])],1)],1)],1),_c('v-dialog',{attrs:{"value":_vm.eliminarIdElemento != '',"persistent":"","max-width":"290px"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Confirmar ação")]),_c('v-card-text',[_vm._v(" Tem a certeza que pretende eliminar o elemento? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":function($event){_vm.eliminarIdElemento = '';
            _vm.eliminarIdEntrada = '';
            _vm.eliminarIdClasse = '';}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.remover('Elemento')}}},[_vm._v(" Confirmar ")])],1)],1)],1),_c('v-snackbar',{attrs:{"color":_vm.color,"timeout":_vm.timeout,"top":true},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.text)+" "),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.fecharSnackbar}},[_vm._v("Fechar")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }